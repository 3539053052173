/*Styles*/
:root {
  --white: #ffffff;
  --shadowgrey: #f2f2f5;
  --maingreen: #2fbfb1;
  --darkblue: #000040;
  --lightblue: #4272f7;
  --mainblue: #0e0e82;
  --mainbackground: #effaf9;
  ;
  --textgrey: #939393;
  --yellow: #ffcc20;
  --btngrey: #84848429;
  --lightgreen: #EFFAF9;
  --bs-primary: red !important;
  --supplierblue: #4272F7;
  --supplierbg: #f5f8ff;
}

[message-badge] {
  position: relative;
}

[message-badge]:after {
  position: absolute;
  right: -5px;
  top: -10px;
  min-width: 20px;
  min-height: 20px;
  line-height: 10px;
  padding: 5px;
  color: #fff;
  background-color: red;
  font-size: 10px;
  border-radius: 20px;
  content: attr(data-badge);
  border: solid 1px #c93a3a;
}


[message-badge-small] {
  position: relative;
}

[message-badge-small]:after {
  position: absolute;
  right: -5px;
  top: -10px;

  min-width: 15px;
  min-height: 15px;

  line-height: 10px;
  padding: 2px;

  color: #fff;
  background-color: red;
  font-size: 8px;
  border-radius: 999px;
  content: attr(data-badge);
  border: solid 0px #c93a3a;
}

[message-badge-smaller] {
  position: relative;
}

[message-badge-smaller]:after {
  position: absolute;
  right: -5px;
  top: -5px;

  min-width: 15px;
  min-height: 15px;

  line-height: 10px;
  padding: 2px;

  color: #fff;
  background-color: red;
  font-size: 8px;
  border-radius: 999px;
  content: attr(data-badge);
  border: solid 0px #c93a3a;
}




[message-badge-smallest] {
  position: relative;
}

[message-badge-smallest]:after {
  position: absolute;
  right: 0px;
  top: -5px;

  min-width: 15px;
  min-height: 15px;

  line-height: 10px;
  padding: 2px;

  color: #fff;
  background-color: red;
  font-size: 8px;
  border-radius: 999px;
  content: attr(data-badge);
  border: solid 0px #c93a3a;
}



[message-badge-bell] {
  position: relative;
}

[message-badge-bell]:after {
  position: absolute;
  right: -5px;
  top: -10px;

  min-width: 10px;
  min-height: 10px;

  line-height: 10px;
  padding: 2px;

  color: #fff;
  background-color: red;
  font-size: 8px;
  border-radius: 999px;
  content: attr(data-badge);
  border: solid 0px #c93a3a;
}











.hovereffect:after {
  position: relative;
  opacity: 0.6;
  background-color: black;
  color: white !important;
  width: 100%;
  padding-top: 30%;
  content: 'Coming Soon' !important;
  font-size: 20px !important;
  transition: 0
}

.badge {

  font-weight: normal !important;
}

#accordionSidebar {
  min-width: 230px;
}

.accordionSidebar {
  min-width: 249px;
}

.form-check-input:bluechecked {
  background-color: var(--lightblue);
  border-color: var(--lightblue);
}

.scrollbox {
  max-height: 400px;
  padding-right: 15px !important;
  overflow-y: scroll;
  border: 25px !important;
}

.rfqbuttons {
  background-color: #84848429 !important;
  border-radius: 5px;

}

.rfqbuttons:focus {
  background-color: #6a6c78 !important;
  border-radius: 5px;
  color: white !important;
}



.rfqbuttonsselected {
  background-color: #6a6c78 !important;
  border-radius: 5px;
  color: white !important;
}


.rfqbuttons:hover {
  background-color: #bdbec4 !important;
  border-radius: 5px;
  color: white;
}

.red {

  background-color: red !important;
}





.RFQmodal {
  height: auto;
  max-height: 800px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 0px 0px 5px 5px !important;
}

.RFQmodal2.p {
  height: auto;
  /*overflow-y: scroll;*/
  /*overflow-x: hidden;*/
  border-radius: 0px 0px 5px 5px !important;
}

.greenformheader {
  background-color: var(--maingreen);
  padding: 1rem;
  border-radius: 5px 5px 0px 0px;
}

.lightblueformheader {
  background-color: var(--lightblue);
  padding: 1rem;
  border-radius: 5px 5px 0px 0px !important;
}


.blueformheader {
  background-color: var(--mainblue);
  padding: 1rem;
  border-radius: 5px 5px 0px 0px !important;
}


.greenformheader {
  background-color: var(--maingreen);
  padding: 1rem;
  border-radius: 5px 5px 0px 0px;
  position: sticky;
  top: 0;
}

body {
  /* background-color: var(--mainbackground); */
  height: 100vh;
}

.blue:focus {
  color: var(--supplierblue) !important;
}

.blue:hover {
  color: var(--supplierblue) !important;
}

.blue::after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: var(--supplierblue) !important;
  color: transparent;
  width: 0%;
  content: '';
  height: 3px;
  transition: all 0.5s;
}


.groen:hover {

  color: red;
  background-color: red;
}



.blue:hover {
  color: var(--supplierblue) !important;
}



.green:focus {
  color: var(--maingreen) !important;
}

.green:hover {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: var(--maingreen) !important;
  color: transparent;
  width: 0%;
  content: '';
  height: 3px;
  transition: all 0.5s;

}



.buyergreennav {

  color: var(--maingreen) !important;



}


.buyergreennav::after {

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: var(--maingreen) !important;
  color: transparent;
  width: 100% !important;
  content: '';
  height: 3px;
  transition: all 0.5s;

}


.navbar .navbar-nav .nav-link::after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #2FBFB1;
  color: transparent;
  width: 0%;
  content: '';
  height: 3px;
  transition: all 0.5s;

}


.groen:hover {
  color: #2FBFB1;

}


/* //HOVER LOGIC FOR BUYER OR SUPPLIER */
.groen:hover::after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  background-color: #2FBFB1;
  color: transparent;
  width: 100% !important;
  content: '';
  height: 3px;
  transition: all 0.5s;
}

.blou:hover {
  color: var(--supplierblue) !important;

}

.blou:hover::after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  background-color: var(--supplierblue) !important;
  color: transparent;
  width: 100% !important;
  content: '';
  height: 3px;
  transition: all 0.5s;
}







.supplierbluenav {

  color: var(--supplierblue) !important;

}

.supplierbluenav::after {

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: var(--supplierblue) !important;
  color: transparent;
  width: 100% !important;
  content: '';
  height: 3px;
  transition: all 0.5s;

}


#wrapper {
  display: flex !important;
}

#wrapper #content-wrapper {
  background-color: #f8f9fc;
  width: 100%;
  overflow-x: hidden;
}

.flex-column {
  flex-direction: column !important;
}

.d-flex {
  display: flex !important;
}

html {
  /*background-color: var(--mainbackground);*/
  /*min-height: 100vh;*/
  overflow-x: hidden !important;
}

.bigheading {
  font-weight: bold;
}

.main {
  padding-top: 5rem;
}

.formtext {
  font-size: 16px;
}


.greentabs {
  background-color: var(--mainbackground);
  /*display: flex;*/
}

.greentab {
  color: var(--textgrey);
}

.nav-link.active.greentab {
  background-color: var(--maingreen);
  color: var(--white);
}

.nav-link.greentab :hover {
  background-color: var(--maingreen);
  color: var(--white);
}

.shortpar {
  max-width: 500px;
  text-align: center;
  justify-content: center;
}

.greencont {
  background-color: var(--maingreen);
  padding: 1rem;
  color: var(--white);
}

.sidepanel {
  background-color: var(--shadowgrey);
  /*height: 100vh;*/
}

.sideitem {
  padding-top: 15px;
  padding-bottom: 15px;
  /*padding-left: 20px;*/
}

.sideitems {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.sidelink {
  text-decoration: none;
  padding-top: 15px;
  padding-bottom: 15px;
  /*padding-left: 15px;*/
  border-radius: 20px;
}

.sidelinkdiv :hover {
  background-color: var(--textgrey);
}

.greyline {
  background-color: var(--textgrey);
  height: 1px;
}

.blueline {
  background-color: var(--darkblue);
  height: 1px;
}

.sidebutton {
  width: 100%;
  background-color: var(--shadowgrey);
  color: var(--darkblue);
  border: 0px;
}

.uiblock {
  padding-top: 15px;
  padding-bottom: 15px;
  /*max-width: 500px;*/
  min-width: 300px;
  box-shadow: large;
}

.uigreyblock {
  background-color: var(--shadowgrey);
  padding: 10px;
}

.uigreyblock {
  background-color: var(--shadowgrey);
  padding: 10px;
}

.uigreyblock {
  background-color: var(--shadowgrey);
  /*padding: 10px;*/
}

.rfqsearchblock {
  padding: 1rem;
  background-color: var(--white);
}

.uidetailblock {
  padding: 10px;
  background-color: var(--white);
}

.uiblocklabel {
  padding: 5px;
  font-weight: bold;
  color: var(--darkblue);
}


#geryblockdark {
  background: var(--textgrey);
}

.greenbutton {
  background-color: var(--lightgreen);
  font-weight: 600;
  color: var(--mainblue);
  border-radius: 20px;
  padding: 10px;
}

.uiblocklabel2 {
  font-weight: 600;
}

.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
}

.bluehead {
  font-size: 20px;
  font-family: Roboto, sans-serif;
  font-weight: bold;
  color: #0e0e82;
  /*font-size: 28px;*/
  /*font-family: Roboto, sans-serif;*/
  /*font-weight: bold;*/
  /*color: #0e0e82;*/
}

.formrow {
  padding: 0px;
}

.rightarrow {
  border-style: solid;
  border-radius: 100px;
  border-width: 3px;
}

.rowborderbottom {
  border-bottom-style: solid;
  border-color: var(--darkblue);
  /*border-width: 3px;*/
  border-width: thin;
}

@media (min-width: 1400px) {
  .RFQFORM {
    max-width: 80%;
  }
}

@media (min-width: 1200px) {
  .RFQFORM {
    max-width: 80%;
  }
}

@media (min-width: 992px) {
  .RFQFORM {
    max-width: 80%;
  }
}

.greybutton {
  border-style: none;
  border-radius: 20px;
  width: 150.0312px;
  height: 42px;
  background-color: #C0C0C0;
  color: var(--textgrey);
}

.radioselect {
  color: var(--textgrey);
}

p {
  color: var(--textgrey);
}

a {
  text-decoration: none;
}

.portalcont {
  padding-bottom: 4rem;
}

.vertline {
  background: var(--textgrey);
  height: 150px;
  margin: 0;
  width: 1px;
}

.buyerimage {
  width: 400px;
}

thead {
  background-color: var(--shadowgrey);
}


.blueheadsmall {
  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: bold;
  color: #0e0e82;
}

.blueheadsmallLB {
  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: bold;
  color: var(--lightblue);
}

.uiicon {
  color: black;
  padding-left: 10px;
  padding-right: 10px;
}

h6 {
  color: var(--textgrey);
}

.logincont {
  padding: 0px;
  /*border-radius: 50px;*/
}


.loginback {
  background: #4272F7;
  width: 100vh;
  height: 100vh;
  /*border: groove;*/
}



@media (min-width: 300px) {
  .loginrightside {
    border-radius: 10px 10px 10px 10px;
  }
}


@media (min-width: 992px) {
  .loginrightside {
    border-radius: 0 10px 10px 0;
  }
}

@media (min-width: 1200px) {
  .loginrightside {
    border-radius: 0 10px 10px 0;
  }
}

@media (min-width: 1400px) {
  .loginrightside {
    border-radius: 0 10px 10px 0;
  }
}

.bg-register-image.loginleftside {
  border-radius: 10px 0 0 10px;
}

form.user .form-control-user {
  font-size: .8rem;
  border-radius: 10px;
  padding: 1rem;
}

.bluebutton {
  background-color: var(--mainblue);
  /*display: block;*/
  width: 100%;
  color: white;
}

.bg-register-image.loginleftside {
  background-color: rg;
}

a:hover {
  color: #3e5cb2;
}


@media (min-width: 1200px) {
  .sidepanel {
    height: 100vh;
  }
}

@media (min-width: 1400px) {
  .sidepanel {
    height: 100vh;
  }
}

@media (min-width: 1200px) {
  .sidepanel {
    width: 200px;
  }
}



@media (min-width: 1400px) {
  .tabcontent {
    padding-right: 0px;
  }
}

.bluetext {
  color: #0e0e82;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.invoicebtn {
  border-radius: 50px;
}

.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem #2fbfb1;
}

.quotebtn {
  background-color: var(--mainblue);
  border-radius: 50px;
  box-shadow: 0 0 0 0.25rem var(--mainblue);
  border: 0px;
  color: white;
}

.expandedrow {
  background-color: var(--btngrey);
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

p {
  margin-bottom: 0.2rem;
}

.timercol {
  background-color: var(--maingreen);
}

.uiinfoblack {
  color: black;
  font-weight: 590 !important;
}

html,
body {
  min-width: 700px !important;
}

#content {
  padding-top: 1rem;
}

.timecircle {
  border-style: solid;
  border-radius: 5rem;
  border-color: white;
  padding: 10px;
}

#yellowblock {
  background-color: var(--yellow);
}

@media (min-width: 1400px) {
  #quoteblock {
    margin-top: 1rem;
  }
}

@media (min-width: 1200px) {
  #quoteblock {
    margin-top: 1rem;
  }
}

@media (min-width: 768px) {
  #quoteblock {
    margin-top: 0rem;
  }
}

@media (min-width: 576px) {
  #quoteblock {
    margin-top: 0rem;
  }
}

@media (min-width: 300px) {
  #quoteblock {
    margin-top: 0rem;
  }
}

.bluetext.shift {
  padding-left: 40px;
  font-weight: 500 !important;
}

.tablerow {
  padding-bottom: 0px !important;
}

.tablesearch {
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 30%;
}

.accordion-button {
  background-color: transparent;
}

.accordion-button {
  color: #4668c900;
  background-color: #edf1fc00;
  box-shadow: 0;
}

.accordion-button:not(.collapsed) {
  /*color: #ff0000 !important;*/
  background-color: rgba(255, 255, 255, 0) !important;
  box-shadow: 0 0 0 0 !important;
}

.accordion-button:not(.collapsed) {
  /*color: #ff0000 !important;*/
  background-color: rgba(255, 255, 255, 0) !important;
  box-shadow: 0 0 0 0 !important;
}

.accordion-button:focus {
  z-index: 3;
  border: 0px !important;
  outline: 0;
  box-shadow: 0 0 0 0 !important;
}

.nopad {
  padding: 0px !important;
}

.bluetext.thin {
  font-weight: 500 !important;
  font-size: 14px !important;
}

.sorthead {
  background-color: var(--shadowgrey) !important;
}

.radio {
  font-size: 14px;
}

.uiblocklabel.sidetext {
  font-weight: 500;
}

.applybtn {
  border-radius: 50px;
  background-color: var(--yellow);
  border: 0px;
}

.applybtn:focus {
  border-radius: 50px;
  background-color: var(--yellow);
  border: 0px;
  box-shadow: 0 0 0 0;
}

.applybtn:hover {
  border-radius: 50px;
  background-color: var(--yellow);
  border: 0px;
}

.applybtn:focus {
  border-radius: 50px;
  background-color: var(--yellow);
  border: 0px;
}

.expandedrow {
  background-color: var(--shadowgrey) !important;
}

.applybtn.active.focus {
  border-radius: 50px;
  background-color: var(--yellow);
  border: 0px;
}

@media (min-width: 992px) {
  #quoteblock {
    margin-top: 1rem;
  }
}

.modal-backdrop {
  opacity: initial;
}

.rfqdetailrow {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.sidebar .nav-item .collapse {
  position: initial;
  left: calc(6.5rem + 1.5rem / 2);
  z-index: 1;
  top: 2px;
}

.copyright {
  margin: 0px !important;
  padding: 50px !important;
}

#content {
  background-color: var(--mainbackground);
}

.yellowbtn:hover {
  background-color: var(--yellow);
  color: white;
}

.yellowbtn {
  background-color: var(--yellow);
  color: white;
}

.footer-basic {
  background-color: rgba(75, 76, 77, 0) !important;
}

.footer-basic {
  height: 100%;
}


.disabled:hover {
  background: #84848429;
  border-style: none;
  border-radius: 20px;
  width: 150.0312px;
  height: 42px;
  color: #C0C0C0;
}

.disabled {
  background: #84848429;
  border-style: none;
  border-radius: 20px;
  width: 150.0312px;
  height: 42px;
  color: #C0C0C0;
}


.bluesearchbtn {
  background-color: var(--mainblue);
  color: white;
  border-radius: 50px;
  margin: 2px;
}


/*Filter RFQ Div*/
.form-check-input:checked {
  background-color: #2fbfb1;
  border-color: #2fbfb1;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--textgrey);
}

.form-check-input:focus {
  border: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #2fbfb1;
}

.btn-primary {
  color: #fff;
  background-color: #2fbfb1;
  border-color: #2fbfb1;
}

.btn-primary:hover {
  color: #fff;
  background-color: #2fbfb1;
  border: none;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #2fbfb1;
  border: none;
  box-shadow: 0 0 0 0.25rem #2fbfb1;
}

.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2fbfb1;
  border: none;
}

p {
  color: var(--textgrey);
}

a {
  text-decoration: none;
}

.bluetext {
  color: #0e0e82;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem #2fbfb1;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

p {
  margin-bottom: 0.2rem;
}

.tablesearch {
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 30%;
}

.bluetext.thin {
  font-weight: 500 !important;
  font-size: 14px !important;
}

.radio {
  font-size: 14px;
}


.applybtn {
  border-radius: 50px;
  background-color: var(--yellow);
  border: 0px;
}

.applybtn:focus {
  border-radius: 50px;
  background-color: var(--yellow);
  border: 0px;
  box-shadow: 0 0 0 0;
}

.applybtn:hover {
  border-radius: 50px;
  background-color: var(--yellow);
  border: 0px;
}



/*focus colours*/
.btn-outline-primary {
  color: var(--maingreen);
  border-color: var(--maingreen);
}

.btn-outline-primary:active {
  color: var(--maingreen);
  border-color: var(--maingreen);
}

.btn-outline-primary:hover {
  background-color: var(--maingreen);
  border-color: var(--maingreen);
}

.btn-check:active+.btn-outline-primary,
.btn-check:checked+.btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  background-color: var(--maingreen);
  border-color: var(--maingreen);
}

.form-check-input:checked {
  background-color: #2fbfb1;
  border-color: #2fbfb1;
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #2fbfb1;
  border-color: #2fbfb1;
  /*background-image: url([svg]);*/
}

.btn-primary.disabled,
.btn-primary:disabled {
  /*color: #fff;*/
  background-color: #2fbfb1;
  border-color: #2fbfb1;
}

.btn-check:active+.btn-outline-primary,
.btn-check:checked+.btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  /*color: #fff;*/
  background-color: #2fbfb1;
  border-color: #2fbfb1;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #2fbfb1;
}

.border-primary {
  border-color: #2fbfb1 !important;
}

.form-select:focus {
  outline: 0;
  border: none;
  box-shadow: 0 0 0 0.25rem #2fbfb1;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--textgrey);
}

.form-check-input:focus {
  border: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #2fbfb1;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem #2fbfb1;
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem #2fbfb1;
}

.btn-primary {
  color: #fff;
  background-color: #2fbfb1;
  border-color: #2fbfb1;
}

.btn-primary:hover {
  color: #fff;
  background-color: #2fbfb1;
  border: none;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #2fbfb1;
  border: none;
  box-shadow: 0 0 0 0.25rem #2fbfb1;
}

.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2fbfb1;
  border: none;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #2fbfb1;
  border-color: #2fbfb1;
}

.btn-check:active+.btn-info:focus,
.btn-check:checked+.btn-info:focus,
.btn-info.active:focus,
.btn-info:active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem #2fbfb1;
}

.btn-check:active+.btn-outline-primary:focus,
.btn-check:checked+.btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
  box-shadow: 0 0 0 0.25rem #2fbfb1;
}

.btn-check:active+.btn-outline-info:focus,
.btn-check:checked+.btn-outline-info:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus,
.btn-outline-info:active:focus {
  box-shadow: 0 0 0 0.25rem #2fbfb1;
}

.nav-link:focus,
.nav-link:hover {
  color: #2fbfb1;
}

.btn-check:active+.btn-info,
.btn-check:checked+.btn-info,
.btn-info.active,
.btn-info:active,
.show>.btn-info.dropdown-toggle {
  color: #000;
  background-color: #2fbfb1;
  border: none;
}

.btn-check:active+.btn-info:focus,
.btn-check:checked+.btn-info:focus,
.btn-info.active:focus,
.btn-info:active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem #2fbfb1;
}

.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem #2fbfb1;
}

.sortdropdown {
  margin: 0 0rem !important;
}


/*FooterBasic*/
.footer-basic {
  padding: 40px 0;
  background-color: #ffffff;
  color: #4b4c4d;
  margin-top: auto;
}

.footer-basic ul {
  padding: 0;
  list-style: none;
  text-align: center;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 0;
}

.footer-basic li {
  padding: 0 10px;
}

.footer-basic ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.8;
}

.footer-basic .social {
  text-align: center;
  padding-bottom: 25px;
}

.footer-basic .social>a {
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #ccc;
  margin: 0 8px;
  color: inherit;
  opacity: 0.75;
}

.footer-basic .social>a:hover {
  opacity: 0.9;
}

.footer-basic .copyright {
  /*margin-top: 15px;*/
  /*text-align: center;*/
  font-size: 13px;
  color: var(--textgrey);
  /*margin-bottom: 0;*/
}

.footer-basic {
  position: relative;
  padding: 10px 10px 0px 10px;
  bottom: 0;
  width: 100%;
  border: 0px !important;
  background-color: transparent;
  height: 30px;
  /*background: grey;*/
}

/*greenblock*/
.uidetailblock {
  padding: 10px;
  background-color: var(--white);
}

.uiblocklabel {
  padding: 5px;
  font-weight: bold;
  color: var(--darkblue);
}

#greenblock {
  background-color: var(--maingreen);
  color: white;
}

#timecircle {
  border: solid 2px;
  border-radius: 20px;
}

/*hoverblocks-compiled*/
.greenhov {
  border: 0px;
  padding: 0px;
  margin: 0px;
  position: relative;
  vertical-align: middle;
}

.greenhov::before,
.greenhov::after {
  box-sizing: inherit;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}

.greenhov:hover {
  color: #2fbfb1;
}

.greenhov::before,
.greenhov::after {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform-origin: greenhov;
}

.greenhov::before {
  border-radius: 10px;
  border-top: 3px solid #2fbfb1;
  border-bottom: 3px solid #2fbfb1;
  transform: scale3d(0, 1, 1);
}

.greenhov::after {
  border-radius: 10px;
  border-left: 3px solid #2fbfb1;
  border-right: 3px solid #2fbfb1;
  transform: scale3d(1, 0, 1);
}

.greenhov:hover::before,
.greenhov:hover::after {
  transform: scale3d(1, 1, 1);
  transition: transform 0.5s;
}

/* Page styling */
.bluehov {
  border: 0px;
  padding: 0px;
  margin: 0px;
  position: relative;
  vertical-align: middle;
}

.bluehov::before,
.bluehov::after {
  box-sizing: inherit;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}

.bluehov:hover {
  color: #4272F7;
}

.bluehov::before,
.bluehov::after {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform-origin: bluehov;
}

.bluehov::before {
  border-radius: 10px;
  border-top: 3px solid #4272F7;
  border-bottom: 3px solid #4272F7;
  transform: scale3d(0, 1, 1);
}

.bluehov::after {
  border-radius: 10px;
  border-left: 3px solid #4272F7;
  border-right: 3px solid #4272F7;
  transform: scale3d(1, 0, 1);
}

.bluehov:hover::before,
.bluehov:hover::after {
  transform: scale3d(1, 1, 1);
  transition: transform 0.5s;
}

/*nav-hover*/
.navbar .navbar-nav .nav-link:hover {
  color: #2FBFB1;
}

@media only screen and (min-width: 960px) {
  .navbar .navbar-nav .nav-link {
    padding: 1em 0.7em;
  }
}

@media only screen and (min-width: 960px) {
  .navbar {
    padding: 0;
  }
}

@media only screen and (min-width: 960px) {
  .navbar .navbar-brand {
    padding: 0 0.7em;
  }
}

.navbar .navbar-nav .nav-link {
  position: relative;
}



.navbar .navbar-nav .nav-link:hover::after {
  width: 100%;
}

/*navigationclean*/
.navigation-clean {
  background: #fff;
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #333;
  border-radius: 0;
  box-shadow: none;
  border: none;
  margin-bottom: 0;
}

@media (min-width:768px) {
  .navigation-clean {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.navigation-clean .navbar-brand {
  font-weight: bold;
  color: inherit;
}

.navigation-clean .navbar-brand:hover {
  color: #222;
}

.navigation-clean .navbar-toggler {
  border-color: #ddd;
}

.navigation-clean .navbar-toggler:hover,
.navigation-clean .navbar-toggler:focus {
  background: none;
}

.navigation-clean .navbar-toggler {
  color: #888;
}

.navigation-clean.navbar-light .navbar-nav .nav-link.active,
.navigation-clean.navbar-light .navbar-nav .nav-link.active:focus,
.navigation-clean.navbar-light .navbar-nav .nav-link.active:hover {
  color: #8f8f8f;
  box-shadow: none;
  background: none;
  pointer-events: none;
}

.navigation-clean.navbar .navbar-nav .nav-link {
  padding-left: 18px;
  padding-right: 18px;
}

.navigation-clean.navbar-light .navbar-nav .nav-link {
  color: #C0C0C0;
}

.navigation-clean.navbar-light .navbar-nav .nav-link:focus,
.navigation-clean.navbar-light .navbar-nav .nav-link:hover {
  color: #2FBFB1;
  background-color: transparent;
}

.navigation-clean .navbar-nav>li>.dropdown-menu {
  margin-top: -5px;
  box-shadow: none;
  background-color: #fff;
  border-radius: 2px;
}

.navigation-clean .dropdown-menu .dropdown-item:focus,
.navigation-clean .dropdown-menu .dropdown-item {
  line-height: 2;
  color: #37434d;
}

.navigation-clean .dropdown-menu .dropdown-item:focus,
.navigation-clean .dropdown-menu .dropdown-item:hover {
  background: #eee;
  color: inherit;
}

/*switch*/
.can-toggle {
  position: relative;
}

.can-toggle *,
.can-toggle *:before,
.can-toggle *:after {
  box-sizing: border-box;
}

.can-toggle input[type=checkbox] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.can-toggle input[type=checkbox][disabled]~label {
  pointer-events: none;
}

.can-toggle input[type=checkbox][disabled]~label .can-toggle__switch {
  opacity: 0.4;
}

.can-toggle input[type=checkbox]:checked~label .can-toggle__switch:before {
  content: attr(data-unchecked);
  left: 0;
}

.can-toggle input[type=checkbox]:checked~label .can-toggle__switch:after {
  content: attr(data-checked);
}

.can-toggle label {
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
}

.can-toggle label .can-toggle__label-text {
  flex: 1;
  padding-left: 32px;
}

.can-toggle label .can-toggle__switch {
  position: relative;
}

.can-toggle label .can-toggle__switch:before {
  content: attr(data-checked);
  position: absolute;
  top: 0;
  text-transform: uppercase;
  text-align: center;
}

.can-toggle label .can-toggle__switch:after {
  content: attr(data-unchecked);
  position: absolute;
  z-index: 5;
  text-transform: uppercase;
  text-align: center;
  background: white;
  transform: translate3d(0, 0, 0);
}

.can-toggle input[type=checkbox][disabled]~label {
  color: rgba(119, 119, 119, 0.5);
}

.can-toggle input[type=checkbox]:focus~label .can-toggle__switch,
.can-toggle input[type=checkbox]:hover~label .can-toggle__switch {
  background-color: #777;
}

.can-toggle input[type=checkbox]:focus~label .can-toggle__switch:after,
.can-toggle input[type=checkbox]:hover~label .can-toggle__switch:after {
  color: #5e5e5e;
}

.can-toggle input[type=checkbox]:hover~label {
  color: #6a6a6a;
}

.can-toggle input[type=checkbox]:checked~label:hover {
  color: #55bc49;
}

.can-toggle input[type=checkbox]:checked~label .can-toggle__switch {
  background-color: #70c767;
}

.can-toggle input[type=checkbox]:checked~label .can-toggle__switch:after {
  color: #4fb743;
}

.can-toggle input[type=checkbox]:checked:focus~label .can-toggle__switch,
.can-toggle input[type=checkbox]:checked:hover~label .can-toggle__switch {
  background-color: #5fc054;
}

.can-toggle input[type=checkbox]:checked:focus~label .can-toggle__switch:after,
.can-toggle input[type=checkbox]:checked:hover~label .can-toggle__switch:after {
  color: #47a43d;
}

.can-toggle label .can-toggle__label-text {
  flex: 1;
}

.can-toggle label .can-toggle__switch {
  transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
  background: #848484;
}

.can-toggle label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.5);
}

.can-toggle label .can-toggle__switch:after {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  color: #777;
}

.can-toggle input[type=checkbox]:focus~label .can-toggle__switch:after,
.can-toggle input[type=checkbox]:hover~label .can-toggle__switch:after {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}

.can-toggle input[type=checkbox]:checked~label .can-toggle__switch:after {
  transform: translate3d(65px, 0, 0);
}

.can-toggle input[type=checkbox]:checked:focus~label .can-toggle__switch:after,
.can-toggle input[type=checkbox]:checked:hover~label .can-toggle__switch:after {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}

.can-toggle label {
  font-size: 14px;
}

.can-toggle label .can-toggle__switch {
  height: 36px;
  flex: 0 0 134px;
  border-radius: 4px;
}

.can-toggle label .can-toggle__switch:before {
  left: 67px;
  font-size: 12px;
  line-height: 36px;
  width: 67px;
  padding: 0 12px;
}

.can-toggle label .can-toggle__switch:after {
  top: 2px;
  left: 2px;
  border-radius: 2px;
  width: 65px;
  line-height: 32px;
  font-size: 12px;
}

.can-toggle label .can-toggle__switch:hover:after {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}

.can-toggle.can-toggle--size-small input[type=checkbox]:focus~label .can-toggle__switch:after,
.can-toggle.can-toggle--size-small input[type=checkbox]:hover~label .can-toggle__switch:after {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

.can-toggle.can-toggle--size-small input[type=checkbox]:checked~label .can-toggle__switch:after {
  transform: translate3d(44px, 0, 0);
}

.can-toggle.can-toggle--size-small input[type=checkbox]:checked:focus~label .can-toggle__switch:after,
.can-toggle.can-toggle--size-small input[type=checkbox]:checked:hover~label .can-toggle__switch:after {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

.can-toggle.can-toggle--size-small label {
  font-size: 13px;
}

.can-toggle.can-toggle--size-small label .can-toggle__switch {
  height: 28px;
  flex: 0 0 90px;
  border-radius: 2px;
}

.can-toggle.can-toggle--size-small label .can-toggle__switch:before {
  left: 45px;
  font-size: 10px;
  line-height: 28px;
  width: 45px;
  padding: 0 12px;
}

.can-toggle.can-toggle--size-small label .can-toggle__switch:after {
  top: 1px;
  left: 1px;
  border-radius: 1px;
  width: 44px;
  line-height: 26px;
  font-size: 10px;
}

.can-toggle.can-toggle--size-small label .can-toggle__switch:hover:after {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

.can-toggle.can-toggle--size-large input[type=checkbox]:focus~label .can-toggle__switch:after,
.can-toggle.can-toggle--size-large input[type=checkbox]:hover~label .can-toggle__switch:after {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}

.can-toggle.can-toggle--size-large input[type=checkbox]:checked~label .can-toggle__switch:after {
  transform: translate3d(78px, 0, 0);
}

.can-toggle.can-toggle--size-large input[type=checkbox]:checked:focus~label .can-toggle__switch:after,
.can-toggle.can-toggle--size-large input[type=checkbox]:checked:hover~label .can-toggle__switch:after {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}

.can-toggle.can-toggle--size-large label {
  font-size: 14px;
}

.can-toggle.can-toggle--size-large label .can-toggle__switch {
  height: 50px;
  flex: 0 0 160px;
  border-radius: 4px;
}

.can-toggle.can-toggle--size-large label .can-toggle__switch:before {
  left: 80px;
  font-size: 14px;
  line-height: 50px;
  width: 80px;
  padding: 0 12px;
}

.can-toggle.can-toggle--size-large label .can-toggle__switch:after {
  top: 2px;
  left: 2px;
  border-radius: 2px;
  width: 78px;
  line-height: 46px;
  font-size: 14px;
}

.can-toggle.can-toggle--size-large label .can-toggle__switch:hover:after {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}

.can-toggle.demo-rebrand-1 input[type=checkbox][disabled]~label {
  color: rgba(181, 62, 116, 0.5);
}

.can-toggle.demo-rebrand-1 input[type=checkbox]:focus~label .can-toggle__switch,
.can-toggle.demo-rebrand-1 input[type=checkbox]:hover~label .can-toggle__switch {
  background-color: #b53e74;
}

.can-toggle.demo-rebrand-1 input[type=checkbox]:focus~label .can-toggle__switch:after,
.can-toggle.demo-rebrand-1 input[type=checkbox]:hover~label .can-toggle__switch:after {
  color: #8f315c;
}

.can-toggle.demo-rebrand-1 input[type=checkbox]:hover~label {
  color: #a23768;
}

.can-toggle.demo-rebrand-1 input[type=checkbox]:checked~label:hover {
  color: #39916a;
}

.can-toggle.demo-rebrand-1 input[type=checkbox]:checked~label .can-toggle__switch {
  background-color: #44ae7f;
}

.can-toggle.demo-rebrand-1 input[type=checkbox]:checked~label .can-toggle__switch:after {
  color: #368a65;
}

.can-toggle.demo-rebrand-1 input[type=checkbox]:checked:focus~label .can-toggle__switch,
.can-toggle.demo-rebrand-1 input[type=checkbox]:checked:hover~label .can-toggle__switch {
  background-color: #3d9c72;
}

.can-toggle.demo-rebrand-1 input[type=checkbox]:checked:focus~label .can-toggle__switch:after,
.can-toggle.demo-rebrand-1 input[type=checkbox]:checked:hover~label .can-toggle__switch:after {
  color: #2f7757;
}

.can-toggle.demo-rebrand-1 label .can-toggle__label-text {
  flex: 1;
}

.can-toggle.demo-rebrand-1 label .can-toggle__switch {
  transition: background-color 0.3s ease-in-out;
  background: #c14b81;
}

.can-toggle.demo-rebrand-1 label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.6);
}

.can-toggle.demo-rebrand-1 label .can-toggle__switch:after {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  color: #b53e74;
}

.can-toggle.demo-rebrand-2 input[type=checkbox][disabled]~label {
  color: rgba(47, 191, 177, 0.5);
}

.can-toggle.demo-rebrand-2 input[type=checkbox]:focus~label .can-toggle__switch,
.can-toggle.demo-rebrand-2 input[type=checkbox]:hover~label .can-toggle__switch {
  background-color: #2fbfb1;
}

.can-toggle.demo-rebrand-2 input[type=checkbox]:focus~label .can-toggle__switch:after,
.can-toggle.demo-rebrand-2 input[type=checkbox]:hover~label .can-toggle__switch:after {
  color: #25968b;
}

.can-toggle.demo-rebrand-2 input[type=checkbox]:hover~label {
  color: #2aab9e;
}

.can-toggle.demo-rebrand-2 input[type=checkbox]:checked~label:hover {
  color: #2cb3a6;
}

.can-toggle.demo-rebrand-2 input[type=checkbox]:checked~label .can-toggle__switch {
  background-color: #39cec0;
}

.can-toggle.demo-rebrand-2 input[type=checkbox]:checked~label .can-toggle__switch:after {
  color: #2aab9e;
}

.can-toggle.demo-rebrand-2 input[type=checkbox]:checked:focus~label .can-toggle__switch,
.can-toggle.demo-rebrand-2 input[type=checkbox]:checked:hover~label .can-toggle__switch {
  background-color: #2fbfb1;
}

.can-toggle.demo-rebrand-2 input[type=checkbox]:checked:focus~label .can-toggle__switch:after,
.can-toggle.demo-rebrand-2 input[type=checkbox]:checked:hover~label .can-toggle__switch:after {
  color: #25968b;
}

.can-toggle.demo-rebrand-2 label .can-toggle__label-text {
  flex: 1;
}

.can-toggle.demo-rebrand-2 label .can-toggle__switch {
  transition: background-color 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  background: #39cec0;
}

.can-toggle.demo-rebrand-2 label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.7);
}

.can-toggle.demo-rebrand-2 label .can-toggle__switch:after {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  color: #2fbfb1;
}

.can-toggle.demo-rebrand-2 input[type=checkbox]:focus~label .can-toggle__switch:after,
.can-toggle.demo-rebrand-2 input[type=checkbox]:hover~label .can-toggle__switch:after {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}

.can-toggle.demo-rebrand-2 input[type=checkbox]:checked~label .can-toggle__switch:after {
  transform: translate3d(58px, 0, 0);
}

.can-toggle.demo-rebrand-2 input[type=checkbox]:checked:focus~label .can-toggle__switch:after,
.can-toggle.demo-rebrand-2 input[type=checkbox]:checked:hover~label .can-toggle__switch:after {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}

.can-toggle.demo-rebrand-2 label {
  font-size: 13px;
}

.can-toggle.demo-rebrand-2 label .can-toggle__switch {
  height: 60px;
  flex: 0 0 120px;
  border-radius: 60px;
}

.can-toggle.demo-rebrand-2 label .can-toggle__switch:before {
  left: 60px;
  font-size: 13px;
  line-height: 60px;
  width: 60px;
  padding: 0 12px;
}

.can-toggle.demo-rebrand-2 label .can-toggle__switch:after {
  top: 2px;
  left: 2px;
  border-radius: 30px;
  width: 58px;
  line-height: 56px;
  font-size: 13px;
}

.can-toggle.demo-rebrand-2 label .can-toggle__switch:hover:after {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}

/*toggle switches*/
fieldset {
  margin: 0;
  /*padding: 2rem;*/
  box-sizing: border-box;
  display: block;
  border: none;
  /*border: solid 1px #CCC;*/
  min-width: 0;
  background-color: #FFF;
}

.toggle {
  margin: 0 0 1.5rem;
  box-sizing: border-box;
  font-size: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
}

.toggle input {
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;
}

.toggle input+label {
  margin: 0;
  padding: .5rem 5rem;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  border: solid 1px #DDD;
  background-color: #FFF;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 600;
  text-align: center;
  box-shadow: 0 0 0 rgba(255, 255, 255, 0);
  transition: border-color .15s ease-out, color .25s ease-out, background-color .15s ease-out, box-shadow .15s ease-out;
}

.toggle input+label:first-of-type {
  border-radius: 6px 0 0 6px;
  border-right: none;
}

.toggle input+label:last-of-type {
  border-radius: 0 6px 6px 0;
  border-left: none;
}

.toggle input:hover+label {
  border-color: #213140;
}

.toggle input:checked+label {
  background-color: var(--maingreen);
  color: #FFF;
  box-shadow: 0 0 10px rgba(102, 179, 251, 0.5);
  z-index: 1;
}

.toggle input:focus+label {
  outline: dotted 1px #CCC;
  outline-offset: .45rem;
}

@media (max-width: 800px) {
  .toggle input+label {
    padding: .75rem .25rem;
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* TradleyNotifs */

.trash {
  border-radius: 20px;
}

.notifbtndiv {
  padding: 1rem;
}

.notifmenu {
  max-width: 300px;
}

.dropdown-list.animated--grow-in.notifmenu {
  max-height: 500px;
  overflow: scroll;
  overflow-x: hidden;


}

::-webkit-scrollbar-thumb {
  background: var(--textgrey);
  border-radius: 25px;



}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);

}

::-webkit-scrollbar {
  width: 3px;
  border-radius: 20px;



  /*height: 12px;*/
}

.notifheader {
  position: sticky !important;
  position: -webkit-sticky;
  top: 0;
}


/*tradely sidebar*/
.sidebar {
  padding: 1rem !important;
  max-width: 18rem !important;
  width: auto;
  min-height: 100vh !important;
  background-color: var(--shadowgrey);
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.sidebar .nav-link:hover,
.sidebar .nav-link.active {
  background-color: var(--textgrey);
  opacity: 1;
}

.sidebar .nav-link span {
  font-size: 1rem;
}

.sidebar .dropdown-toggle:after {
  display: none;
}

.sidebar .dropdown-menu {
  position: relative !important;
  padding: 0;
  margin: 0;
  width: 100%;
  overflow: hidden;
  transform: unset !important;
  top: unset !important;
  left: unset !important;
  min-width: unset !important;
  background-color: var(--shadowgrey);
  border-radius: 0 !important;
}

.sidebar .dropdown-item {
  padding: 0.8rem 0 0.8rem 1.5rem;
  margin: 0;
}

.sidebar .dropdown-item:hover,
.sidebar .dropdown-item:active,
.dropdown-item:focus {
  background-color: var(--bs-info);
}


.sidebar .nav-link {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  font-size: 1rem;
  position: relative;
  opacity: 0.9;
}

@media (max-width: 991px) {
  .sidebar.mobile-hid .nav-item {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .sidebar.mobile-hid {
    width: 0 !important;
  }
}

.Searchbox {
  padding: 15px;
}

.searchinput {
  border-radius: 10px;
  border: 0px;
}

.sortdropdown {
  border-radius: 10px;
  background-color: var(--lightgrey);
  color: var(--textgrey);
  border: 0px;
}

.navlabel {
  color: var(--darkblue);
  font-weight: 600;
}

#sidebarToggle {
  color: var(--maingreen);
}

.navlabel {
  font-size: 16px;
}

/* Bluefocus */
.btn-outline-info {
  color: var(--mainblue);
  border-color: var(--mainblue);
}

.btn-outline-info:active {
  color: rgb(255, 255, 255);
  border-color: var(--mainblue);
}

.btn-outline-info:hover {
  background-color: var(--mainblue);
  border-color: var(--mainblue);
  color: #ffffff;
}

.btn-check:active+.btn-outline-info,
.btn-check:checked+.btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
  background-color: var(--mainblue);
  border-color: var(--mainblue);
  color: white;
}

.btn-check:active+.btn-outline-primary:focus,
.btn-check:checked+.btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
  box-shadow: 0 0 0 0.25rem var(--mainblue);
}

.btn-check:focus+.btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem var(--mainblue);
}

/* Supplierstyles */
.supplierheader {
  background-color: var(--supplierblue) !important;
}

.bluebtn {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}

.smallcheckbtn {
  padding: 5px;
}




/* TOOLTIPS */

/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  display: flex;

  word-wrap: break-word;
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 10px;
  width: 150px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: left;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 500;
}




/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}



/* WIDER TOOLTIPS */

/* Add this attribute to the element that needs a tooltip */
[data-tooltip-wider] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip-wider]:before,
[data-tooltip-wider]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip-wider]:before {
  display: flex;

  word-wrap: break-word;
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 10px;
  width: 400px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip-wider);
  text-align: left;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 500;
}




/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip-wider]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip-wider]:hover:before,
[data-tooltip-wider]:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}